import {Component, Input, ViewEncapsulation, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {App} from 'src/app/app';
import {Service} from '../../../http/service';
import {ServiceList} from '../../../http/service-list';
import {FileUtils} from '../../../utils/file-utils';
import {Resource} from '../../../models/resource';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';
import {UnoButtonComponent} from '../../uno/uno-button/uno-button.component';

@Component({
	selector: 'uno-audio-selector',
	templateUrl: './uno-audio-selector.component.html',
	styleUrls: ['uno-audio-selector.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => { return UnoAudioSelectorComponent; }),
			multi: true
		}
	],
	standalone: true,
	imports: [IonicModule, TranslateModule, UnoIconComponent, UnoButtonComponent]
})
export class UnoAudioSelectorComponent implements ControlValueAccessor {
	public static FORMATS: string = '.mp3, .wma, .wav, .flac, .m4a';
	
	public app: any = App;
	
	/**
	 * Allow the input to be disabled.
	 */
	@Input()
	public disabled = false;

	/**
	 * Resource representing the image stored.
	 */
	public value: Resource = null;

	/**
	 * Method called when the data is changed.
	 */
	public onChange: (value: any)=> void = function() {};

	/**
	 * Get the image URL to be displayed on this component.
	 */
	public get audioUrl(): any {
		return Service.getURL(ServiceList.resources.file.get, {
			uuid: this.value.uuid,
			format: this.value.format
		});
	}

	/**
	 * Select image file from the user.
	 */
	public async selectFile(): Promise<void> {
		const files: File[] = await FileUtils.chooseFile(UnoAudioSelectorComponent.FORMATS, false);
		if (files.length > 0) {
			await this.uploadFile(files[0]);
		}
	}

	/**
	 * Method called when a new file is inserted.
	 *
	 * @param file - File to be uploaded.
	 */
	public async uploadFile(file: File): Promise<void> {
		const format = FileUtils.getFileExtension(file);

		const form = new FormData();
		form.append('file', file, 'audio');
		form.append('format', format);

		const request = await Service.fetch(ServiceList.resources.file.upload, null, null, form, null);

		this.value = new Resource(request.response.uuid, format);
		this.onChange(this.value);
	}

	public updateValue(value: Resource): void {
		this.value = value;
		this.onChange(value);
	}

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public writeValue(value: any): void {
		this.value = value;
	}

	public registerOnTouched(fn: any): void {}

	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
