@if (this.object) {
	@if (row.type === types.SUB_FORM) {
		<!-- Sub-Forms -->
		<div style="padding-top: 5px; padding-bottom: 5px;">
			<uno-form-collapsable-field [showRequiredUneditable]="true" [editable]="this.editable" [object]="row.attribute ? (this.row | formValue : this.object) : this.object" [row]="this.row"></uno-form-collapsable-field>
		</div>
	}
	@else if (row.type === types.COMPOSED_FIELD) {
		<!-- Composed Field -->
		<uno-form-composed-field [showRequiredUneditable]="true" (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" [row]="this.row"></uno-form-composed-field>
	}
	@else if (row.type === types.TITLE) {
		<!-- Title -->
		<uno-title>{{(this.row | label:(this.row | formValue : this.object)) | translate}}</uno-title>
	}
	@else {
		@if (row.type !== types.KEY_VALUE_ARRAY && row.type !== types.TEXT_LIST && (row.type !== types.UUID || (this.row | formValue : this.object))) {
			<div style="padding-top: 5px; padding-bottom: 5px;">
				@if (row.type === types.EMAIL) {
					@if (!((this.row | formValue : this.object) | validateEmail)) {
						<ion-note color="danger" style="font-size: 11px; font-weight: bold;">{{'invalidEmail' | translate}}</ion-note>
						<br>
					}
				}
				<div style="display: flex; align-items: center;">
					@if (row.type === types.ATEX_INSPECTION_FIELD) {
						<!-- ATEX inspection field present row and label -->
						<ion-label class="ion-text-wrap">{{(row.attribute | translate) + ' - ' + ($any(row.label) | translate)}}</ion-label>
					} @else if (row.type != types.CHECKBOX) {
						<!-- Checkbox present label alongside the input -->
						<ion-label class="ion-text-wrap">{{(this.row | label:(this.row | formValue : this.object)) | translate}}</ion-label>
					}
					@if ((this.editable || (!this.editable && this.showRequiredUneditable)) && (row.required | checkbool:false:this.object:row) && (this.row | fieldEmpty : this.object)) {
						<span class="uno-p2" style="font-size: 14px; font-weight: bold; font-style: italic; margin-left: 8px; color: var(--error-normal);">{{'required' | translate}}</span>
					}
				</div>
			</div>
		}

		@if (row.type === types.REPETITIVE_FORM) {
			<!-- Repetitive Form-->
			<uno-form-repetitive-field [showRequiredUneditable]="true" (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [layout]="this.row.fields" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" [row]="this.row"></uno-form-repetitive-field>
		} @else if (row.type === types.TEXT) {
			<!-- Text -->
			<ion-input (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" fill="outline" type="text">
				@if (row.copy) {
					<uno-icon slot="end" class="uno-uuid-icon" (click)="this.copy(row);" [width]="32" [height]="32" [color]="'var(--brand-primary)'" src="./assets/components/uuid/copy-icon.svg"></uno-icon>
				}
			</ion-input>
		}
		@else if (row.type === types.PHONE_NUMBER) {
			<!-- Telephone -->
			<uno-phone-number (ngModelChange)="this.setAttribute(row, $event);" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" [disabled]="!this.editable"></uno-phone-number>
		}
		@else if (row.type === types.EMAIL) {
			<!-- Email -->
			<ion-input (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" fill="outline" type="email"></ion-input>
		}
		@else if (row.type === types.NUMBER) {
			<!-- Number -->
			<div style="display: flex; flex-direction: row;">
				<ion-input (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" fill="outline" type="number"></ion-input>

				<!-- Unit -->
				@if (row.inUnit !== undefined) {
					<ion-text style="padding-left: 5px">{{row.inUnit}}</ion-text>
				}
			</div>
		}
		@else if (row.type === types.NUMBER_UNITS) {
			<!-- Number Unit -->
			<div style="display: flex; flex-direction: row;">
				<!-- Input Value -->
				<ion-input (ngModelChange)="this.setAttribute(row, unitConverter.convert($event, row.inUnit, row.unit))" [disabled]="!this.editable" [ngModel]="((this.row | formValue : this.object) | convertUnit : row.unit : row.inUnit)" [ngModelOptions]="{standalone: true}" fill="outline" type="number"></ion-input>
				<!-- Unit Selector -->
				<ion-select [(ngModel)]="row.inUnit" [ngModelOptions]="{standalone: true}" interface="{{app.device.isMobile() ? 'alert' : 'popover'}}" style="width: 80px;" fill="outline">
					@for (option of row.options; track option) {
						<ion-select-option [value]="option.value">{{option.label | translate}}</ion-select-option>
					}
				</ion-select>
			</div>

		}
		@else if (row.type === types.PASSWORD) {
			<!-- Password -->
			<ion-input (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" fill="outline" type="password">
				<ion-input-password-toggle slot="end"></ion-input-password-toggle>
			</ion-input>
		}

		@else if (row.type === types.TEXT_MULTILINE) {
			<!-- Text multiline -->
			<ion-textarea (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" autoGrow="true" fill="outline"></ion-textarea>
		}
		@else if (row.type === types.UUID) {
			@if ((this.row | formValue : this.object)) {
				<!-- UUID -->
				<uno-uuid style="width:100%" fill="outline" [uuid]="(this.row | formValue : this.object)"></uno-uuid>
			}
		}
		@else if (row.type === types.CHECKBOX) {
			<!-- Checkbox -->
			<ion-checkbox justify="start" labelPlacement="end" (ngModelChange)="this.setAttribute(row, row.invert ? !$event : $event);" [disabled]="!this.editable" [ngModel]="row.invert ? !(this.row | formValue : this.object) : (this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}">
				{{(this.row | label:(this.row | formValue : this.object) | translate)}}
			</ion-checkbox>
		}
		@else if (row.type === types.SWITCH) {
			<!-- Switch -->
			<uno-switch (ngModelChange)="this.setAttribute(row, row.invert ? !$event : $event);" [disabled]="!this.editable" [ngModel]="row.invert ? !(this.row | formValue : this.object) : (this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-switch>
		}
		@else if (row.type === types.DATE) {
			<!-- Date -->
			<uno-date-time (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" placeholder="{{'selectDate' | translate}}"></uno-date-time>
		}
		@else if (row.type === types.IMAGE_RESOURCE) {
			<!-- Image resource -->
			<uno-image-selector (ngModelChange)="this.setAttribute(row, $event);" [local]="row.local !== undefined ? row.local : false" [multiple]="false" [compress]="row.compress !== undefined ? row.compress : true" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-image-selector>
		}
		@else if (row.type === types.IMAGE_RESOURCE_MULTIPLE) {
			<!-- Image resource multiple -->
			<uno-image-selector style="width:40%" (ngModelChange)="this.setAttribute(row, $event);" [local]="row.local !== undefined ? row.local : false" [multiple]="true" [compress]="row.compress !== undefined ? row.compress : true" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-image-selector>
		}
		@else if (row.type === types.VIDEO_RESOURCE) {
			<!-- Video resource -->
			<uno-video-selector (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-video-selector>
		}
		@else if (row.type === types.AUDIO_RESOURCE) {
			<!-- Audio resource -->
			<uno-audio-selector (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-audio-selector>
		}
		@else if (row.type === types.OPTIONS) {
			<!-- Options -->
			<uno-options (ngModelChange)="this.setAttribute(row, $event);" [translate]="this.row.translate !== undefined ? this.row.translate : true" [disabled]="!this.editable" [displayMode]="row.optionsDisplayMode" [sort]="row.sort" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" [options]="row.options" [row]="this.row" [object]="this.object"></uno-options>
		}
		@else if (row.type === types.OPTIONS_MULTIPLE) {
			<!-- Options Multiple -->
			<uno-options-multiple (ngModelChange)="this.setAttribute(row, $event);" [translate]="this.row.translate !== undefined ? this.row.translate : true" [disabled]="!this.editable" [displayMode]="row.optionsDisplayMode" [showClear]="row.showClear" [sort]="row.sort" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" [options]="row.options" [row]="this.row" [object]="this.object"></uno-options-multiple>
		}
		@else if (row.type === types.OPTIONS_SEARCH) {
			<!-- Options w/ Search -->
			<uno-options-search (ngModelChange)="this.setAttribute(row, $event);" [options]="row.options" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-options-search>
		}
		@else if (row.type === types.PERIODICITY_SELECTOR) {
			<!-- Periodicity selector -->
			<uno-periodicity-selector (ngModelChange)="this.setAttribute(row, $event);" [min]="row.min" [max]="row.max" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-periodicity-selector>
		}
		@else if (row.type === types.LATERAL_SELECTOR) {
			<!-- Lateral Selector -->
			<uno-lateral-selector-switch (ngModelChange)="this.setAttribute(row, $event);" [options]="row.options" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-lateral-selector-switch>
		}
		@else if (row.type === types.OPTIONS_LIST) {
			<!-- Options List -->
			<uno-options-list (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" [options]="row.options"></uno-options-list>
		}
		@else if (row.type === types.ASSET_SELECTOR) {
			<!-- Asset list -->
			<!-- <uno-asset-selector (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [assetTypeUuid]="row.assetTypeUuid" [assetSubTypeUuid]="row.assetSubTypeUuid" [showClear]="row.showClear" [sort]="row.sort" [multiple]="row.multiple" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-asset-selector> -->
			<uno-asset-selector-list (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [showClear]="row.showClear" [sort]="row.sort" [multiple]="row.multiple" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-asset-selector-list>
		}
		@else if (row.type === types.TEAM_SELECTOR) {
			<!-- Team selector -->
			<uno-team-selector (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [showClear]="row.showClear" [sort]="row.sort" [multiple]="row.multiple" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-team-selector>
		}
		@else if (row.type === types.COMPANY_SELECTOR) {
			<!-- Company selector -->
			<uno-company-selector (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [showClear]="row.showClear" [sort]="row.sort" [multiple]="row.multiple" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-company-selector>
		}
		@else if (row.type === types.USER_SELECTOR) {
			<!-- User selector -->
			<uno-user-selector (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [showClear]="row.showClear" [sort]="row.sort" [multiple]="row.multiple" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-user-selector>
		}
		@else if (row.type === types.OPTIONS_MULTIPLE_LAZY) {
			<!-- Multiple options lazy -->
			<uno-options-lazy (ngModelChange)="this.setAttribute(row, $event);" [translate]="this.row.translate !== undefined ? this.row.translate : true" [disabled]="!this.editable" [showClear]="row.showClear" [fetchOptionsBatch]="row.fetchOptionsBatch" [fetchOptionsLazy]="row.fetchOptionsLazy" [getOptionText]="row.getOptionText" [identifierAttribute]="row.identifierAttribute" [multiple]="row.multiple !== false" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" [placeholder]="row.placeholder ? row.placeholder : 'empty'" [onClose]="row.onClose" [onOpen]="row.onOpen" [onSelect]="row.onSelect" [params]="this.object">
			</uno-options-lazy>
		}
		@else if (row.type === types.GEO_POSITION) {
			<!-- Location -->
			<uno-geo-position (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" style="width: 200px; min-width: 150px;"></uno-geo-position>
		}
		@else if (row.type === types.ATEX_INSPECTION_FIELD) {
			<!-- Atex Inspection field -->
			<uno-atex-inspection-field (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" [row]="row"></uno-atex-inspection-field>
		}
		@else if (row.type === types.ATEX_FIELD) {
			<!-- Atex field -->
			<uno-atex-tag-group (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" [row]="row"></uno-atex-tag-group>
		}
		@else if (row.type === types.DATE_FREQUENCY) {
			<!-- Date Frequency (months, years) -->
			<uno-date-frequency (ngModelChange)="this.setAttribute(row, $event);" [type]="row.dateFrequencyPrecision" [ngModel]="(row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-date-frequency>
		}
		@else if (row.type === types.DOCUMENT_RESOURCE_MULTIPLE) {
			<!-- Document multiple files -->
			<uno-file-multiple-selector (ngModelChange)="this.setAttribute(row, $event);" [local]="row.local !== undefined ? row.local : false" [disabled]="!this.editable" [filter]="row.filter || ''" [ngModel]="(this.row | formValue : this.object)" [multiple]="true" [ngModelOptions]="{standalone: true}"></uno-file-multiple-selector>
		}
		@else if (row.type === types.DOCUMENT_RESOURCE) {
			<!-- Document file -->
			<uno-file-multiple-selector (ngModelChange)="this.setAttribute(row, $event);" [local]="row.local !== undefined ? row.local : false" [disabled]="!this.editable" [filter]="row.filter || ''" [ngModel]="(this.row | formValue : this.object)" [multiple]="false" [ngModelOptions]="{standalone: true}" [sampleFile]="row.sampleData || null"></uno-file-multiple-selector>
		}
		@else if (row.type === types.COLOR) {
			<!-- Color -->
			<label>
				<input (change)="this.setAttribute(row, $event.srcElement.value);" [disabled]="!this.editable" [value]="(this.row | formValue : this.object)" style="width: 120px; height: 40px; cursor: pointer; border-width: 1px; border-style: solid; box-shadow: none; border-color: var(--gray-10); border-radius: 4px; appearance: none;" type="color" />
			</label>
		}
		@else if (row.type === types.QR) {
			<!-- QR -->
			<uno-qr-code (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-qr-code>
		}
		@else if (row.type === types.NFC) {
			<!-- NFC -->
			<uno-nfc-tag (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}"></uno-nfc-tag>
		}
		@else if (row.type === types.TEXT_LIST) {
			<!-- Text Array -->
			<uno-key-value mode="text" [row]="this.row" (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" [title]="row.label" fill="outline" style="max-width: 85%;"></uno-key-value>
		}
		@else if (row.type === types.KEY_VALUE_ARRAY) {
			<!-- Key-value Array -->
			<uno-key-value mode="key-value" [row]="this.row" (ngModelChange)="this.setAttribute(row, $event);" [disabled]="!this.editable" [ngModel]="(this.row | formValue : this.object)" [ngModelOptions]="{standalone: true}" [title]="row.label" fill="outline" style="max-width: 85%;"></uno-key-value>
		}
	}
}
