import {ObjectUtils} from 'src/app/utils/object-utils';
import {ServiceResponse} from '../../../../http/service-response';
import {APAssetFieldData} from '../../../../models/asset-portfolio/asset-field-data';
import {AssetReportUtils} from '../../../asset-portfolio/data/asset-report-utils';
import {AssetService} from '../../../asset-portfolio/services/asset.service';
import {APAssetFormTab} from '../../../../models/asset-portfolio/asset-form-tab';
import {APAsset} from '../../../../models/asset-portfolio/asset';
import {FileUtils} from '../../../../utils/file-utils';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {InspectionWorkflowStep} from '../../../../models/inspections/workflow/inspection-workflow-step';
import {UUID} from '../../../../models/uuid';
import {InspectionForm} from '../../../../models/inspections/form/inspection-form';
import {InspectionFormField} from '../../../../models/inspections/form/inspection-form-field';
import {DocxUtils} from '../../../../utils/docx-utils';
import {AssetReport} from '../../../asset-portfolio/data/asset-report';
import {InspectionFormFieldType} from '../form/inspection-form-field-type';
import {InspectionProject} from '../../../../models/inspections/project/inspection-project';
import {InspectionWorkflow} from '../../../../models/inspections/workflow/inspection-workflow';
import {InspectionReportTemplate} from '../../../../models/inspections/project/inspection-report-template';
import {Resource} from '../../../../models/resource';
import {APAssetType} from '../../../../models/asset-portfolio/asset-type';
import {InspectionFormService} from '../../services/inspection-form.service';
import {Inspection} from '../../../../models/inspections/inspection/inspection';
import {InspectionDataFields} from '../../../../models/inspections/inspection/inspection-data';

export class InspectionReport {
	/**
	 * Path of the default report template.
	 */
	public static defaultReportURL: string = 'assets/template/inspection-template.docx';

	/**
	 * Get all the forms and subforms of a form by their UUID recursively.
	 * 
	 * @param formUuid - The UUID of the form to get the data and subforms data for.
	 * @param forms - Map with already loaded forms if any (optional).
	 * @returns Map with all forms loaded from API
	 */
	public static async loadForms(formUuid: UUID, forms: Map<UUID, InspectionForm> = new Map<UUID, InspectionForm>()): Promise<Map<UUID, InspectionForm>> {
		// If there is no form to be fetch or the form has already been fetched, return the whole forms map
		if (!formUuid || forms.has(formUuid)) {
			return forms;
		}

		const form: InspectionForm = await InspectionFormService.get(formUuid, true, false);
		forms.set(form.uuid, form);
		
		for (let i = 0; i < form.fields.length; i++) {
			// Recursively call sub-form data
			if (form.fields[i].isSubform()) {
				if (!form.fields[i].subFormUuid) {
					throw new Error('Invalid Subform UUID.');
				}

				await InspectionReport.loadForms(form.fields[i].subFormUuid, forms);
			}
		}
		
		return forms;
	}

	/**
	 * Generate a docx document from inspection data and report associated with the project.
	 *
	 * @param inspection - Inspection object to generate report for.
	 * @param template - Inspection project report template.
	 * @param additionalData - Additional data to be provided to the report. Object provided is merged with the default params. (Override can occur)
	 * @param assets - Map with preloaded assets if any.
	 * @param steps - Map with preloaded steps if any.
	 * @param forms - Map with prelaoded forms if any.
	 */
	public static async generateDocx(inspection: Inspection, template: InspectionReportTemplate | ArrayBuffer, additionalData: any = {}, assets: Map<UUID, APAsset> = new Map<UUID, APAsset>(), steps: Map<UUID, InspectionWorkflowStep> = new Map<UUID, InspectionWorkflowStep>(), forms: Map<UUID, InspectionForm> = new Map<UUID, InspectionForm>()): Promise<ArrayBuffer> {
		const workflows: Map<UUID, InspectionWorkflow> = new Map<UUID, InspectionWorkflow>();

		// Get an asset by its UUID. If the asset is already on cache, it will be returned from it. If not, the asset is fetched from the API.
		async function getAsset(assetUuid: UUID): Promise<APAsset> {
			if (!assetUuid) {
				return null;
			}
			
			let asset: APAsset;
			
			if (assets) {
				asset = assets.get(assetUuid);
			}
			
			if (!asset) {
				asset = await AssetService.get(assetUuid);
			}

			if (!assets) {
				assets.set(asset.uuid, asset);
			}

			return asset;
		}

		// Get a workflow by its UUID. If the workflow is already on workflows cache, it will be returned from it. If not, the workflow is fetched from the API.*/
		async function getWorkflow(workflowUuid: UUID): Promise<InspectionWorkflow> {
			if (!workflowUuid) {
				return null;
			}
			
			let workflow: InspectionWorkflow;

			if (workflows) {
				workflow = workflows.get(workflowUuid);
			}
			
			if (!workflow) {
				const workflowReq = await Service.fetch(ServiceList.inspection.workflow.get, null, null, {uuid: workflowUuid}, Session.session, true, false);
				workflow = InspectionWorkflow.parse(workflowReq.response.workflow);	
			}

			if (workflows) {
				workflows.set(workflow.uuid, workflow);
			}
			
			return workflow;
		}
		
		// Project data
		const project: InspectionProject = InspectionProject.parse((await Service.fetch(ServiceList.inspection.project.get, null, null, {uuid: inspection.projectUuid}, Session.session, true)).response.project);

		// Template file
		let arraybuffer: ArrayBuffer = null; 
		if (template instanceof ArrayBuffer) {
			arraybuffer = template;
		} else {
			if (!template.template) {
				throw new Error('Cannot generate report without a report template available.');
			}

			arraybuffer = await FileUtils.readFileArrayBuffer(Service.getURL(ServiceList.resources.file.get, {uuid: template.template.uuid, format: template.template.format}));
		}

		// Asset data
		let assetFields: any[] = [];
		let assetPhotos: Resource[] = [];
		let asset: APAsset = null;
		let assetTabs: APAssetFormTab[] = [];
		
		// Asset parent data
		let assetParent: APAsset = null;
		let assetParentFields: {attr: string, value: any}[] = [];
		
		if (inspection.assetUuid) {
			asset = await getAsset(inspection.assetUuid);
			
			assetFields = AssetReport.getAssetData(asset);
			assetPhotos = AssetReport.getAssetImages(asset);

			// Get asset parent data
			if (asset.parentUuid && asset.parentUuid.length > 0) {
				assetParent = await getAsset(asset.parentUuid);

				assetParentFields = AssetReport.getAssetData(assetParent);
			}

			// Get asset strucutre tabs (tabs from the asset type and sub-type only)
			assetTabs = await AssetReport.getAssetStructureTabsContent(asset.typeUuid, asset.subTypeUuid);
		}

		// Store asset dynamic data by tab UUID
		const assetTabsData: Map<UUID, any> = new Map<UUID, any>();

		// Get asset tabs fields data
		const requestAssetTabsFieldData = await Service.fetch(ServiceList.assetPortfolio.asset.data.list, null, null, {assetUuid: asset.uuid}, Session.session);
		const fieldsData: APAssetFieldData[] = requestAssetTabsFieldData.response.data.map((d: any) => {
			return APAssetFieldData.parse(d);
		});

		// Set every tab data object from asset fields data
		for (let j = 0; j < assetTabs.length; j++) {
			const tab: APAssetFormTab = assetTabs[j];

			assetTabsData.set(tab.uuid, APAsset.setFieldsTabDataObject(tab, fieldsData));
		}

		// Inspection step
		let step: InspectionWorkflowStep = steps.get(inspection.stepUuid);
		if (!step) {
			const stepReq: ServiceResponse = await Service.fetch(ServiceList.inspection.workflowStep.get, null, null, {uuid: inspection.stepUuid}, Session.session, true);
			step = InspectionWorkflowStep.parse(stepReq.response.step);

			// Add step to the cache
			steps.set(step.uuid, step);
		}

		// Inspection project workflow
		const workflow: InspectionWorkflow = await getWorkflow(project.workflowUuid);

		// Extract steps into a map
		for (const s of workflow.steps) {
			if (!steps.get(s.uuid)) {
				steps.set(s.uuid, s);
			}
		}

		// Extract all the workflow steps form and its form sub-forms
		for (let i = 0; i < workflow.steps.length; i++) {
			// Check if workflow has form
			if (workflow.steps[i].formUuid) {
				await InspectionReport.loadForms(workflow.steps[i].formUuid, forms);
			}
		}

		// Map of all the inspection fields by field UUID
		const fieldsMap: Map<UUID, InspectionFormField> = new Map();
		forms.forEach((form: InspectionForm) => {
			for (const f of form.fields) {
				fieldsMap.set(f.uuid, f);
			}
		});

		// List of all fields available in the inspection
		const inspectionFieldResponses: {label: string, text: string, value: any, type: number, uuid: UUID, data: any, path: any[]}[] = [];

		// Recursive method to get the inspection data
		function extractInspectionData(data: InspectionDataFields, path: any[] = []): void {
			// The keys/indexes of the data in the array are the UUIDs of the fields
			for (const fieldUuid in data) {
				const field = fieldsMap.get(fieldUuid);
				const value = data[fieldUuid];

				// Check if field exists
				if (field) {
					const p = path.concat([field.uuid]);

					inspectionFieldResponses.push({
						uuid: field.uuid,
						label: field.label,
						text: field.text,
						type: field.type,
						data: field.data,
						value: value,
						path: p
					});

					if (field.type === InspectionFormFieldType.SUB_FORM || field.type === InspectionFormFieldType.COMPOSED_FIELD) {
						extractInspectionData(value, p);
					}
				}
			}
		}

		// Prepare inspection fields
		for (const stepData of inspection.data) {
			extractInspectionData(stepData.data);
		}

		// Assets fields map by asset UUID.
		const assetFieldsData: Map<UUID, APAssetFieldData[]> = new Map<UUID, APAssetFieldData[]>();

		const data = {
			field: {
				// Get field by its label
				label: (label: string): any => {return inspectionFieldResponses.find((val) => {return val.label === label;}) || null;},
				// Get field by its text
				text: (text: string): any => {return inspectionFieldResponses.find((val) => {return val.text === text;}) || null;},
				// Get field by UUID or by path (e.g. UUID1.UUID2.0.UUID3)
				uuid: (uuid: UUID|string): any => {
					const path = uuid.split('.');
					
					return inspectionFieldResponses.find((val) => {
						if (val.path.length !== path.length) {
							return false;
						}

						for (let i = 0; i < path.length; i++) {
							if (val.path[i] !== path[i]) {
								return false;
							}
						}
						return true;
					});
				}
			},
			fields: inspectionFieldResponses,
			project: project,
			inspection: inspection,
			workflowsMap: workflow,
			formsMap: forms,
			fieldsMap: fieldsMap,
			stepsMap: steps,
			step: step,
			asset: asset,
			assetFields: assetFields,
			assetPhotos: assetPhotos,
			assetParent: assetParent,
			assetTabs: assetTabs,
			assetTabsData: assetTabsData,
			getAsset: async function(assetUuid: UUID): Promise<any[]> { return ObjectUtils.toArray(await getAsset(assetUuid));},
			getAssetType: function(uuid: UUID): APAssetType {
				// Fetch asset type
				const typeRequest: ServiceResponse = Service.fetchSync(ServiceList.assetPortfolio.assetType.get, null, null, {uuid: uuid}, Session.session);
								
				// Parse response into a proper object array
				return APAssetType.parse(typeRequest.response.type);
			},
			getAssetAncestors: function(assetUuid: UUID): APAsset[] {
				// Fetch all the asset ancestors
				const ancestorsRequest: ServiceResponse = Service.fetchSync(ServiceList.assetPortfolio.asset.listAncestors, null, null, {uuid: assetUuid}, Session.session);
				
				// Parse response into a proper object array
				return ancestorsRequest.response.assets.map((d: any) => { return APAsset.parse(d); });
			},
			getAssetAttribute: async function(assetUuid: UUID, attr: string): Promise<any> {return (await getAsset(assetUuid))[attr]; },
			getAssetFieldsData: async function(assetUuid: UUID): Promise<APAssetFieldData[]> { return await AssetReportUtils.getAssetFieldsData(assetUuid, assetFieldsData); },
			assetParentFields: assetParentFields,
			fieldTypes: InspectionFormFieldType
		};

		Object.assign(data, additionalData);

		return await DocxUtils.generateDocxFromTemplate(arraybuffer, data);
	}
}
