import {
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {Chart, ChartData} from 'chart.js';
import {NgStyle} from '@angular/common';
import {CssNgStyle} from '../../../utils/css-ng-style';

@Component({
	selector: 'dashboard-doughnut-chart',
	templateUrl: './dashboard-doughnut-chart.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgStyle]
})
export class DashboardDoughnutChartComponent implements OnInit, OnDestroy {
	@ViewChild('canvas', {static: true})
	public canvas: ElementRef = null;

	/**
	 * Data to display in the doughnut chart.
	 */
	@Input()
	public data: ChartData = null;

	/**
	 * Style of the doughnut chart.
	 */
	@Input()
	public ngStyle: CssNgStyle = {height: '100%'};

	/**
	 * Context of the doughnut chart.
	 */
	public context: CanvasRenderingContext2D = null;

	/**
	 * The chart to display
	 */
	public chart: Chart = null;

	public ngOnInit(): void {
		this.context = this.canvas.nativeElement.getContext('2d');

		this.chart = new Chart(this.context, {
			type: 'doughnut',
			data: this.data,
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					tooltip: {
						callbacks: {
					  		label: (d) => {
								// Show both the value and slice percentage while hovering.
								const total = d.dataset.data.filter(Number).reduce((a, b) => {return Number(a) + Number(b);}, 0);
								// Compute percentage
								return ' ' + d.raw + ' (' + (Number(d.raw) / Number(total) * 100).toFixed(2) + '%)';
					  		}
						}
				  	},
					legend: {
						labels: {
							usePointStyle: true,
							pointStyle: 'circle',
							boxWidth: 6,
							boxHeight: 6,
							font: {
								family: 'Nunito',
								size: 12,
								lineHeight: 24
							}
						},
						display: true,
						position: 'bottom'
					}
				}
			}
		});
	}

	public ngOnDestroy(): void {
		if (this.chart) {
			this.chart.destroy();
			this.chart = null;
		}
	}
}
