<div class="uno-widget-container">
	<div class="uno-widget-text-container">
		<h3 class="uno-h7-bold uno-widget-title">{{this.widget.name}}</h3>
		<h3 class="uno-p2 uno-widget-subtitle">{{this.widget.description}}</h3>
	</div>
	<div class="uno-widget-data-container">
		@if (this.widget.data.chart === 'bar') {
			<dashboard-bar-chart [data]="this.chartData"></dashboard-bar-chart>
		} @else if (this.widget.data.chart === 'line') {
			<dashboard-line-chart [data]="this.chartData"></dashboard-line-chart>
		} @else if (this.widget.data.chart === 'doughnut') {
			<dashboard-doughnut-chart [data]="this.chartData"></dashboard-doughnut-chart>
		}
	</div>
</div>
