<div class="uno-video-selector">
	@if (this.value !== null) {
		<div class="uno-video-selector-video-container">
			<div class="uno-video-selector-videos-container">
				<video controls src="{{this.videoUrl}}" style="display: block; max-width: 300px; max-height: 180px; cursor: pointer;"></video>
				@if (!this.disabled) {
					<div class="uno-video-selector-video-delete-icon" (click)="this.writeValue(null)">
						<uno-icon [width]="25" [height]="25" [src]="'assets/icons/uno/close.svg'" [color]="'var(--gray-12)'"></uno-icon>
					</div>
				}
			</div>
		</div>
	}
	@if (!this.value) {
		@if (this.disabled) {
			<div class="uno-video-selector-select-container" style="cursor: not-allowed;">
				<div class="uno-video-selector-select-container-text">
					<uno-icon src="assets/icons/uno/cam.svg" color="primary" width="32" height="32"></uno-icon>
					<div>
						<span>{{'noDataOnServer' | translate }}</span>
					</div>
				</div>
			</div>
		}
		@else {
			<div #upload class="uno-video-selector-select-container" (click)="this.selectFile()">
				<div class="uno-video-selector-select-container-text">
					<uno-icon src="assets/icons/uno/cam.svg" color="primary" width="32" height="32"></uno-icon>
					<div>
						@if (app.device.isMobile()) {
							<span class="uno-video-selector-choose-file">{{'chooseFile' | translate }}</span>
							<span>{{'or' | translate }}</span>
							<span class="uno-video-selector-choose-file">{{'openCamera' | translate }}</span>
							<span>{{'toUpload' | translate }}</span>
						} @else {
							<span>{{'dragAndDrop' | translate }}</span>
							<span>{{'or' | translate }}</span>
							<span class="uno-video-selector-choose-file">{{'chooseFile' | translate }}</span>
							<span>{{'toUpload' | translate }}</span>
						}

					</div>
				</div>
				<span class="uno-video-selector-container-sublabel">{{'videoFiles' | translate }}</span>
			</div>
		}
	}
</div>
