import {Component, Input, forwardRef, ViewEncapsulation, WritableSignal, signal} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import {NgClass} from '@angular/common';
import {CdkDrag, CdkDragDrop, CdkDropList, CdkDragPlaceholder, moveItemInArray} from '@angular/cdk/drag-drop';
import {UUID, generateUUID} from '../../../models/uuid';
import {UnoTextComponent} from '../../uno/uno-text/uno-text.component';
import {UnoIconComponent} from '../../uno/uno-icon/uno-icon.component';
import {UnoFormUtils} from '../../uno-forms/uno-form/uno-form-utils';
import {UnoFormField} from '../../uno-forms/uno-form/uno-form-field';
import {UnoFormCheckBooleanPipe} from '../../uno-forms/uno-form/pipes/uno-form-check-boolean.pipe';
import {UnoFormFieldTypes} from '../../uno-forms/uno-form/uno-form-field-types';

/**
 * Represents a key-value pair.
 */
export class KeyValue {
	/**
	 * The key to relate the value with.
	 */
	public key: any;

	/**
	 * The value pair for the key.
	 */
	public value: any;

	public constructor(key?: UUID, value?: string) {
		this.key = key || generateUUID();
		this.value = value;
	}
}

export enum UnoKeyValueComponentMode {
	/**
	 * The component stores a key value pair for each item.
	 */
	KEY_VALUE = 'key-value',

	/**
	 * A string array is stored in the component.
	 */
	TEXT = 'text'
}

@Component({
	selector: 'uno-key-value',
	templateUrl: './uno-key-value.component.html',
	styleUrls: ['./uno-key-value.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => { return UnoKeyValueComponent; }),
		multi: true
	}],
	standalone: true,
	imports: [UnoFormCheckBooleanPipe, IonicModule, NgClass, FormsModule, UnoTextComponent, TranslateModule, CdkDropList, CdkDrag, UnoIconComponent, CdkDragPlaceholder]
})
export class UnoKeyValueComponent implements ControlValueAccessor {
	/**
	 * Row of the form being edited in this field, used to fetch additional details.
	 */
	@Input()
	public row: UnoFormField = null;
	
	/**
	 * Disables the input.
	 */
	@Input()
	public disabled = false;

	/**
	 * Component mode, can be either a key-value pair or a string array.
	 */
	@Input()
	public mode: UnoKeyValueComponentMode = UnoKeyValueComponentMode.KEY_VALUE;

	/**
	 * String for the title
	 */
	@Input()
	public title: string;

	/**
	 * Values representing the array of key-values.
	 */
	public items: KeyValue[] | string[] = [];

	/**
	 * Verifies if the user is dragging a value
	 */
	public isDragging: boolean;

	/**
	 * Verifies if the list is empty
	 */
	public isEmpty: WritableSignal<boolean> = signal(true);

	public onChange: (value: any)=> void = function() {};

	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	public registerOnTouched(fn: any): void {}

	public writeValue(value: KeyValue[] | string[]): void {
		this.items = structuredClone(value);
		this.isEmpty = signal(UnoFormUtils.valueEmpty(this.items, this.mode === UnoKeyValueComponentMode.KEY_VALUE ? UnoFormFieldTypes.KEY_VALUE_ARRAY : UnoFormFieldTypes.TEXT_LIST));

		this.onChange(this.items);
	}

	/**
	 * Sets the component edition state.
	 * 
	 * @param disabled - If true, the component value input cannot be modified.
	 */
	public setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	/**
	 * Set the value of an item in the list by its index.
	 *
	 * @param index - Index of the item to be edited.
	 * @param value - New value to be put in the item.
	 */
	public setItem(index: number, value: string): void {
		if (this.mode === UnoKeyValueComponentMode.KEY_VALUE) {
			// @ts-ignore
			this.items[index].value = value;
		} else {
			this.items[index] = value;
		}
		
		this.writeValue(this.items);
	}

	/**
	 * Remove an item from the array by its index.
	 *
	 * @param index - Index of the item to be removed.
	 */
	public removeItem(index: number): void {
		if (index !== -1 && !UnoFormUtils.checkBool(this.row.disableDelete, false, this.items, this.row)) {
			this.items.splice(index, 1);
			this.writeValue(this.items);
		}
	}

	/**
	 * Add a new item to array list.
	 */
	public addItem(): void {
		if (!this.items || !(this.items instanceof Array)) {
			this.items = [];
		}
		
		if (this.mode === UnoKeyValueComponentMode.KEY_VALUE) {
			// @ts-ignore
			this.items.push(new KeyValue());
		} else {
			// @ts-ignore
			this.items.push('');
		}

		this.writeValue(this.items);
	}

	/**
	 * Changes isDragging when dragging starts
	 */
	public drag(): void {
		if (!UnoFormUtils.checkBool(this.row.disableSort, false, this.items, this.row)) {
			this.isDragging = true;
		}
	}

	/**
	 * Changes array after finishing the drag and drop
	 * 
	 * @param event - DragDrop event
	 */
	public drop(event: CdkDragDrop<KeyValue[] | string[]>): void {
		if (!UnoFormUtils.checkBool(this.row.disableSort, false, this.items, this.row)) {
			moveItemInArray(this.items as string[], event.previousIndex, event.currentIndex);
			this.isDragging = false;
		}
	}
}
