import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgClass} from '@angular/common';
import {DashboardWidget} from 'src/app/models/dashboards/widget';
import {WidgetsMap} from 'src/app/models/dashboards/widgets-map';
import {DashboardBarChartComponent} from 'src/app/components/dashboard/bar-chart/dashboard-bar-chart';
import {WidgetTypes} from 'src/app/models/dashboards/widget-types';
import {DashboardLineChartComponent} from 'src/app/components/dashboard/line-chart/dashboard-line-chart.component';
import {DashboardDoughnutChartComponent} from 'src/app/components/dashboard/doughnut-chart/dashboard-doughnut-chart.component';

export type ChartData = {
	// Labels to use on the chart.
	labels: string[],
	// Labels to apply on the chart.
	datasets: any[]
}

/**
 * The Dashboard widget component.
 */
@Component({
	selector: 'uno-widget',
	templateUrl: 'widget.component.html',
	styleUrls: ['widget.component.css'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgClass, DashboardBarChartComponent, DashboardLineChartComponent, DashboardDoughnutChartComponent]
})
export class UnoWidgetComponent implements OnInit {
	/**
	 * The widget to show.
	 */
	@Input()
	public widget: DashboardWidget;

	/**
	 * The chart data to use on the charts
	 */
	public chartData: ChartData;

	/**
	 * The widget interface.
	 */
	@Input()
	public widgetInterface: DashboardWidgetInterface;

	public ngOnInit(): void {
		if (this.widget.type in WidgetTypes) {
			this.fetchData();
		} else {
			console.error('EQS: Widget Type does not exist');
		}
	}

	/**
	 * Fetches the corresponding widget and it's values.
	 */
	public fetchData(): void {
		this.widgetInterface = WidgetsMap.get(this.widget.type);
		this.chartData = this.widgetInterface.getData(this.widget);
	}
}
